import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import '../../index.css';

export const LoadingComponent = () => {
  const theme = useTheme();
  const styles = {
    container: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };
  return (
    <Box sx={styles.container}>
      <Typography
        className="loading-text"
        variant="h6"
        color="primary.light"
        marginBottom="3rem"
        fontSize="2vmin"
        fontWeight={700}
      >
        just a second
      </Typography>
      <Typography color="primary.main">
        <CircularProgress />
      </Typography>
    </Box>
  );
};
