import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2EC4B6',
      light: '#F6F7F8',
      dark: '#011627',
      contrastText: '#FF3366',
    },
    secondary: {
      main: '#7160eb',
      light: '#F4F1DE',
      dark: '#3D405B',
      contrastText: '#20A4F3',
    },
  },
  typography: {
    fontFamily: "'Lato', sans-serif",
  },
  input: {
    fontFamily: "'Lato', sans-serif",
  },
});
