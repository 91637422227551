import { Box } from '@mui/material';
import { LandingPageContent } from './LandingContent';
import { LandingBackground } from './LandingBackground';

export const Landing = () => {
  const styles = {
    landingPageContainer: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'end',
      height: '100vh',
    },
  };
  return (
    <Box
      color="primary.light"
      component={'main'}
      sx={styles.landingPageContainer}
    >
      <LandingPageContent />
      {window.innerHeight > 400 && <LandingBackground />}
    </Box>
  );
};
