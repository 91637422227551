import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const AboutMe = () => {
  const theme = useTheme();
  const styles = {
    componentContainer: {
      backgroundColor: theme.palette.primary.dark,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    boldLine: {
      zIndex: 500,
      width: '95%',
      height: '3vmin',
      backgroundColor: theme.palette.secondary.contrastText,
    },
    neutralLine: {
      zIndex: 500,
      width: '95%',
      height: '1vmin',
      backgroundColor: theme.palette.primary.light,
      boxShadow: `3px 3px 3px ${theme.palette.primary.light},
        -3px -3px 3px ${theme.palette.primary.light},
        -3px 3px 3px ${theme.palette.primary.light},
        5px 0 4px 2px ${theme.palette.primary.light},
        3px -3px 3px ${theme.palette.primary.light}`,
    },
    flipped: {
      transform: 'rotate(180deg)',
    },
    content: {
      zIndex: 500,
      height: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
  return (
    <Box component={'div'} sx={styles.componentContainer}>
      <Box component={'div'} sx={styles.flipped}>
        <Box component={'div'} sx={styles.neutralLine} />
      </Box>
      <Box component={'div'} sx={styles.content}>
        <Typography
          fontWeight={700}
          fontSize="3vmin"
          color="primary.light"
          variant="h6"
        >
          coming soon
        </Typography>
      </Box>
      <Box component={'div'} sx={styles.neutralLine} />
    </Box>
  );
};
