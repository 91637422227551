import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SocialLinks } from '../SocialLinks';
import CloseIcon from '@mui/icons-material/Close';

export const SubmissionConfirmation = ({ handleModal }) => {
  const theme = useTheme();
  const styles = {
    pageContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.palette.primary.light,
      textAlign: 'center',
      padding: '.5rem',
      borderRadius: '1rem',
    },
    button: {
      backgroundColor: theme.palette.secondary.main,
      marginTop: '2rem',
    },
    itemContainers: {
      width: '500px',
      maxWidth: '95%',
    },
  };
  return (
    <Box sx={styles.pageContainer}>
      <Box
        padding="1rem 2rem 2rem 2rem"
        borderBottom={`.5px solid ${theme.palette.secondary.dark}`}
      >
        <SocialLinks
          props={{
            position: 'relative',
            color: 'primary.dark',
            justify: 'center',
          }}
        />
        <Typography
          fontSize="2vmin"
          fontWeight={700}
          color="secondary.dark"
          paddingTop="1rem"
          color="secondary.contrastText"
        >
          Feel free to check out my socials
        </Typography>
      </Box>
      <Box padding="2rem">
        <Typography
          fontSize="2.5vmin"
          fontWeight={700}
          color="secondary.dark"
        >
          Your message has been sent and I'll be in touch soon!
        </Typography>

        <Button
          size="large"
          sx={styles.button}
          onClick={() => handleModal()}
          variant="contained"
        >
          <Typography color="primary.light" fontWeight={700}>
            ok
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
