import { SocialLinks } from './SocialLinks';
import { useTheme } from '@mui/material/styles';

export const NavBar = () => {
  const theme = useTheme();
  return (
    <SocialLinks
      props={{
        position: 'fixed',
        color: 'primary.light',
        justify: 'end',
      }}
    />
  );
};
