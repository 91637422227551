import image from '../../AI-image-3.png';
import { Box, Fade } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const LandingBackground = () => {
  const theme = useTheme();
  const styles = {
    backgroundContent: {
      width: '600px',
      height: '80%',
      backgroundColor: theme.palette.primary.main,
      boxShadow: `-1rem -2rem 2rem 1rem ${theme.palette.primary.main},
        1rem -2rem 2rem 1rem ${theme.palette.primary.main},
        -1rem 0 1rem ${theme.palette.primary.main}`,
      backgroundImage: `url(${image})`,
      backgroundPosition: 'bottom center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '300px',
      borderRadius: '75vmin 50vmin 0 20vmin',
    },
  };
  return (
    <Fade in timeout={{ enter: 500 }}>
      <Box component={'div'} sx={styles.backgroundContent} />
    </Fade>
  );
};
