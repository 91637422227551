import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import { AppBar, Link, Box } from '@mui/material';

export const SocialLinks = ({ props }) => {
  const styles = {
    navigation: {
      position: props.position,
      padding: '.5rem 1rem 0 1rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: props.justify,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      boxShadow: 'none',
    },
  };
  return (
    <AppBar className="nav-bar" sx={styles.navigation}>
      <Link
        margin="5px"
        color={props.color}
        target="_blank"
        rel="noreferrer"
        href="https://www.linkedin.com/in/stephen1010"
      >
        <LinkedInIcon />
      </Link>
      <Link
        margin="5px"
        color={props.color}
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/yewzer.name"
      >
        <FacebookIcon />
      </Link>
      <Link
        margin="5px"
        color={props.color}
        target="_blank"
        rel="noreferrer"
        href="https://www.github.com/stevelandoh"
      >
        <GitHubIcon />
      </Link>
    </AppBar>
  );
};
