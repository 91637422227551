import { theme } from './themes';

export const titleShadow = [
  `1px 1px 3px ${theme.palette.primary.light}`,
  `-1px -1px 3px ${theme.palette.primary.light}`,
  `-1px 1px 3px ${theme.palette.primary.light}`,
  `1px -1px 3px ${theme.palette.primary.light}`,
  `4px 4px 8px ${theme.palette.primary.dark}`,
];

export const FORM_DATA = {
  recruiter: [
    ['JOB TITLE *', 'role', 'text', true],
    ['LINK TO JOB DESCRIPTION *', 'description', 'text', true],
    ['BUDGETED SALARY', 'salary', 'text', false],
    ['BEST CONTACT EMAIL *', 'email', 'email', true],
    ['NAME OF CONTACT *', 'name', 'text', true],
  ],
  recruiterTitle: 'Contact Stephen - Available Role',
  client: [
    ['TYPE OF PROJECT *', 'role', 'text', true],
    ['PROJECT DESCRIPTION *', 'description', 'text', true],
    ['BUDGET', 'salary', 'text', false],
    ['BEST CONTACT EMAIL *', 'email', 'email', true],
    ['NAME OF CONTACT *', 'name', 'text', true],
  ],
  clientTitle: 'Contact Stephen - Freelance Inquiry',
};

export const FORM_ENDPOINT =
  'https://public.herotofu.com/v1/3ef07fc0-582f-11ee-b04f-17ba4776c03d';
