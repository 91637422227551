import './index.css';
import { NavBar } from './Components/NavBar';
import { Footer } from './Components/Footer';
import { AboutMe } from './Components/AboutMe';
import { Landing } from './Components/Landing/Landing';
import { LoadingComponent } from './Components/Loading/LoadingComponent';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

export const App = () => {
  const theme = useTheme();
  const styles = {
    container: {
      backgroundColor: theme.palette.primary.dark,
      minHeight: '100vh',
    },
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 1800);
  }, []);

  if (!isLoaded) {
    return (
      <Box component={'div'} sx={styles.container}>
        <LoadingComponent />
      </Box>
    );
  }
  return (
    <Box component={'div'} sx={styles.container}>
      <NavBar />
      <Landing />
      <AboutMe />
      <Footer />
    </Box>
  );
};
