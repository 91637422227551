import {
  Typography,
  Box,
  Button,
  Fade,
  ButtonGroup,
  Modal,
} from '@mui/material';
import { useState } from 'react';
import '../../index.css';
import { ContactForm } from '../ContactForm/ContactForm';
import { useTheme } from '@mui/material/styles';
import { LandingTitle } from './LandingTitle';

export const LandingPageContent = () => {
  const theme = useTheme();
  const styles = {
    contentContainer: {
      width: '100%',
      height: '100%',
      zIndex: 100,
      position: 'absolute',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
    },
    subtitleText: {
      cursor: 'default',
      textShadow: `2px 2px 3px ${theme.palette.primary.dark}`,
      margin: '1rem',
      fontSize: '3vmin',
    },
    button: {
      color: theme.palette.primary.light,
      fontWeight: 700,
      fontSize: '3vmin',
      backgroundColor: theme.palette.secondary.main,
      margin: '2px',
      boxShadow: `2px 2px 8px ${theme.palette.primary.dark}`,
      padding: '1rem',
      '&:hover': {
        color: theme.palette.primary.dark,
        backgroundColor: 'rgba(246,247,248, .8)',
        boxShadow: `4px 4px 8px ${theme.palette.primary.dark}`,
      },
    },
  };

  const [clientIsOpen, setClientIsOpen] = useState(false);
  const [recruiterIsOpen, setRecruiterIsOpen] = useState(false);

  const handleRecruiter = () => setRecruiterIsOpen(!recruiterIsOpen);
  const handleClient = () => setClientIsOpen(!clientIsOpen);

  return (
    <Fade in timeout={{ enter: 1200 }}>
      <Box component={'div'} style={styles.contentContainer}>
        <LandingTitle />
        <ButtonGroup sx={{ marginTop: '1rem' }}>
          <Button
            onClick={() => handleRecruiter()}
            sx={styles.button}
            variant="contained"
          >
            For Recruiters
          </Button>
          <Modal key={0} open={recruiterIsOpen} disableScrollLock>
            <>
              <ContactForm
                type="recruiter"
                setIsOpen={setRecruiterIsOpen}
                isOpen={recruiterIsOpen}
              />
            </>
          </Modal>
          <Button
            onClick={() => handleClient()}
            sx={styles.button}
            variant="contained"
          >
            For Clients
          </Button>
          <Modal key={1} open={clientIsOpen} disableScrollLock>
            <>
              <ContactForm
                type="client"
                setIsOpen={setClientIsOpen}
                isOpen={clientIsOpen}
              />
            </>
          </Modal>
        </ButtonGroup>
        <Typography
          className="blinking-text"
          variant="h6"
          sx={styles.subtitleText}
        >
          scroll down for more
        </Typography>
      </Box>
    </Fade>
  );
};
