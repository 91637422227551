import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
export const Footer = () => {
  const theme = useTheme();
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.dark,
      padding: '3rem',
    },
  };
  return (
    <Box
      component={'footer'}
      className="footer"
      sx={styles.container}
    >
      <Box
        component={'div'}
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          fontSize="2vmin"
          color="primary.light"
          variant="h6"
        >
          who made this?
        </Typography>
        <Typography
          fontSize="2vmin"
          color="primary.light"
          variant="h6"
        >
          <Box
            component={'span'}
            sx={{
              color: '#E07A5F',
            }}
          >
            ©
          </Box>
          2023
        </Typography>
      </Box>
    </Box>
  );
};
