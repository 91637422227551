import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FORM_DATA } from '../../utils/data';
import { useTheme } from '@mui/material/styles';

export const FormTitle = ({ props }) => {
  const theme = useTheme();
  const styles = {
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '1rem',
      borderBottom: `.5px solid ${theme.palette.secondary.dark}`,
      justifyContent: 'space-between',
    },
  };
  return (
    <Box sx={styles.titleContainer}>
      <Typography
        color="secondary.dark"
        fontWeight={700}
        fontSize={'3vmin'}
        variant="h6"
      >
        {props.type === 'recruiter'
          ? FORM_DATA.recruiterTitle
          : FORM_DATA.clientTitle}
      </Typography>
      <CloseIcon
        sx={{ cursor: 'pointer' }}
        fontSize="2vmin"
        onClick={() => props.setIsOpen(!props.isOpen)}
        color="primary.contrastText"
      />
    </Box>
  );
};
