import {
  Box,
  Button,
  ButtonGroup,
  Input,
  InputLabel,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { SubmissionConfirmation } from './SubmissionConfirmation';
import { FORM_DATA, FORM_ENDPOINT } from '../../utils/data';
import CloseIcon from '@mui/icons-material/Close';
import { FormTitle } from './FormTitle';
export const ContactForm = (props) => {
  const theme = useTheme();
  const { type, isOpen, setIsOpen } = props;
  const [submitted, setSubmitted] = useState(false);
  const styles = {
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.palette.primary.light,
      borderRadius: '1rem',
      maxWidth: '100vw',
    },
    bottomContainer: {
      borderTop: `.5px solid ${theme.palette.secondary.dark}`,
      marginTop: '2rem',
      padding: '1rem',
      textAlign: 'right',
    },
    inputsContainer: {
      margin: '0 2rem',
    },
    text: {
      color: theme.palette.secondary.dark,
      border: `1px solid ${theme.palette.secondary.dark}`,
      width: '500px',
      maxWidth: '100%',
      borderRadius: '5px',
      margin: '3px 0 0 5px',
      padding: '0 1rem',
      fontFamily: "'Lato', sans-serif",
      fontSize: '2vmin',
    },
    inputLabel: {
      marginTop: '1rem',
      fontWeight: 700,
    },
    button: {
      border: 'none',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '10px',
      margin: '1rem',
      padding: '.5rem 2rem',
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const inputs = e.target.elements;
    const data = {};
    Object.values(inputs).forEach((input) => {
      if (input.name) data[input.name] = input.value;
    });
    try {
      let response = await fetch(FORM_ENDPOINT, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`response result: ${response.status}`);
      }
      setSubmitted(true);
    } catch (err) {
      // Submit the form manually
      e.target.submit();
    }
  };

  if (submitted) {
    return (
      <SubmissionConfirmation
        handleModal={() => setIsOpen(!isOpen)}
      />
    );
  }

  return (
    <Box
      component={'form'}
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="POST"
      style={styles.formContainer}
    >
      <FormTitle
        props={{
          type: type,
          isOpen: isOpen,
          setIsOpen: () => setIsOpen(),
        }}
      />
      <Box sx={styles.inputsContainer}>
        {FORM_DATA[type].map((input, index) => (
          <>
            <InputLabel sx={styles.inputLabel}>
              <Typography
                fontWeight={700}
                fontSize="2vmin"
                variant="subtitle2"
                color="secondary.main"
              >
                {input[0]}
              </Typography>
            </InputLabel>
            <Input
              sx={styles.text}
              key={index}
              type={input[2]}
              name={input[1]}
              required={input[3]}
              autoComplete={`contact-section input-${input[1]}`}
              disableUnderline
            />
          </>
        ))}
      </Box>
      <Box sx={styles.bottomContainer}>
        <Typography
          fontWeight={700}
          fontSize="1.75vmin"
          variant="subtitle2"
          textAlign="right"
          marginRight="2rem"
          color="secondary.dark"
        >
          This form will generate an email and I will be in touch
        </Typography>

        <Button
          size="large"
          sx={styles.button}
          variant="contained"
          type="submit"
        >
          <Typography
            fontWeight={700}
            fontSize="3vmin"
            color="primary.light"
          >
            send
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
