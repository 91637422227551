import { Typography } from '@mui/material';
import { titleShadow } from '../../utils/data';
import { useTheme } from '@mui/material/styles';

export const LandingTitle = () => {
  const theme = useTheme();

  const styles = {
    h1Text: {
      cursor: 'default',
      fontSize: '18vmin',
      fontWeight: 700,
      letterSpacing: '-5px',
      textShadow: `${titleShadow[0]},
        ${titleShadow[1]},
        ${titleShadow[2]},
        ${titleShadow[3]},
        ${titleShadow[4]}`,
    },
  };
  return (
    <>
      <Typography
        variant="h1"
        sx={{ ...styles.h1Text, marginLeft: '2rem' }}
      >
        web
      </Typography>
      <Typography
        variant="h1"
        sx={{ ...styles.h1Text, marginTop: '-1.5rem' }}
      >
        developer
      </Typography>
    </>
  );
};
